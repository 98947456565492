import {createRouter, createWebHistory} from 'vue-router';
import SignView from '../views/sign-view/sign-view';
import LoginForm from '../views/sign-view/login/login';
import HomeView from '../views/main/home/home.vue';
import MainView from '../views/main/main-view.vue';
import RegistrationForm from '../views/sign-view/registration/registration.vue';
import ResetPasswordForm from '@/views/sign-view/reset-password/reset-password.vue';
import store from "@/store";
import UserEventsView from '@/views/main/user-events/user-events-view.vue';
import UserTokensView from '@/views/main/user-tokens/user-tokens-view.vue';
import AdminOauthClientsView from "../views/main/admin-oauth-clients/admin-oauth-clients-view.vue";
import AdminOauthClientView from "../views/main/admin-oauth-clients/admin-oauth-client.vue";
import AdminUserView from "../views/main/admin-user/admin-user-view.vue";
import LoginService from "@/views/sign-view/login/service/login-service";

const routes = [
    {
        // всё что касается авторизации / регистрации будет находиться по пути '/auth'
        path: '/auth',
        component: SignView,
        children: [
            {
                path: 'login',
                name: 'login',
                component: LoginForm,
                meta: {
                    title: "Авторизация"
                }
            },

            // добавляем путь для формы регистрации
            {
                path: 'registration',
                name: 'registration',
                component: RegistrationForm,
                meta: {
                    title: "Регистрация"
                }
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: ResetPasswordForm,
                meta: {
                    title: "Сброс пароля"
                }
            }
        ]
    },
    {
        path: "/",
        component: MainView,
        redirect: '/home',
        children: [
            {
                path: 'home',
                name: 'home',
                component: HomeView,
                meta: {
                    title: "Профиль"
                }
            },
            {
                path: 'events',
                name: 'events',
                component: UserEventsView,
                meta: {
                    title: "События"
                }
            },
            {
                path: 'tokens',
                name: 'tokens',
                component: UserTokensView,
                meta: {
                    title: "Токены"
                }
            },
            {
                path: 'admin-oauth-clients',
                redirect: '/admin-oauth-clients/list',
                children: [
                    {
                        path: 'list',
                        name: 'admin-oauth-clients',
                        component: AdminOauthClientsView,
                        meta: {
                            title: "OAuth клиенты"
                        }
                    },
                    {
                        path: ':clientId?',
                        name: 'admin-oauth-client',
                        component: AdminOauthClientView,
                        meta: {
                            title: "OAuth клиент"
                        }
                    },
                    {
                        path: '/new/:clientId',
                        name: 'admin-oauth-client-new',
                        component: AdminOauthClientView,
                        meta: {
                            title: "Создать OAuth клиента"
                        }
                    }
                ]
            },
            {
                path: 'admin-users',
                name: 'admin-users',
                component: AdminUserView,
                meta: {
                    title: "Администраторы"
                }
            }
        ]
    }
];

const router = createRouter({

    // Добавим "контекст" для клиента
    history: createWebHistory("/authorize"),
    routes,
});

// Добавим хук, который будет выполняться при каждом переходе и проверять авторизован ли пользователь.
// Если нет, то будет совершать переход на страницу входа.
router.beforeEach((to, from, next) => {
    if (to.path === '/oauth/continue') {
        LoginService.afterOauthLoginSuccess().then(()=>{
            router.replace({name: 'home'});
        });
        return;
    }

    if (to.path === '/oauth/logout') {
        LoginService.logout().then(() => {
            if (to.query['redirectUri']) {
                window.location.href = to.query['redirectUri'] + '&client_id=' + to.query['client_id']
                    + '&redirect_uri=' + to.query['redirect_uri']
                    + '&scope=' + to.query['scope']
            }
            router.replace({name: 'login'});
            }
        )
        return;
    }

    const nearestWithTitle = to.meta.title;

    const previousNearestWithMeta = from.meta.title;

    // If a route with a title was found, set the document (page) title to that value.
    if (nearestWithTitle) {
        document.title = nearestWithTitle;
    } else if (previousNearestWithMeta) {
        document.title = previousNearestWithMeta;
    }
    if (to.name && to.path) {

        // Если пользователь не авторизован и мы переходим на любую страницу кроме "login" и "registration",
        // то перенаправлять нас на страницу "login"
        //
        // home - нужен так как в сценарии, когда мы просто хотим зайти в SSO через строний сервис (например yandex),
        // success handler перенаправляет нас на страницу /home. Но так как, данное приложение не загрузило ещё
        // текущего пользователя, то оно нас просто перенаправит на страницу логина.
        // Поэтому, обработка неавторизованной сессии в home вынесена в сам компонент home.vue.
        if (!store.getters.isAuth && !["login", "registration", "reset-password"].includes(to.name)) {
            router.replace({name: 'login'});
            return;
        }
        next();
    }
});

export default router;
