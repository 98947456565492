import {configure, defineRule} from 'vee-validate';
import {localize, setLocale} from '@vee-validate/i18n';
import ru from '@vee-validate/i18n/dist/locale/ru.json';
import {required, email, max} from '@vee-validate/rules'

export default {
    install(Vue, opts) {
        defineRule('required', required)
        defineRule('email', email)
        defineRule('max', max)

        defineRule('confirmed', (value, [target], ctx) => {
            if (value === ctx.form[target]) {
                return true;
            }
            return 'Пароли не совпадают';
        });

        configure({
            generateMessage: localize({
                ru
            })
        });
        setLocale("ru");
    }
}

